import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import { useRef  } from "react"
import Layout from "../components/core/Layout"
import Navigation from "../components/core/Navigation"

import beyondSymbol from "../images/common/logo-beyond-my-menopause.png"
import beyondSymbolWhite from "../images/common/symbol-beyond-white.svg"
import locationIcon from "../images/common/location-icon.svg"
import phoneIcon from "../images/common/phone-icon.svg"
import quoteIcon from "../images/common/quote-icon.svg"

import membershipIconTwo from "../images/home/membership-two-@2x.png"
import membershipIconThree from "../images/home/membership-three-@2x.png"

const IndexPage = () => {


  const refFaq1 = useRef(null);
  const refFaq2 = useRef(null);
  const refFaq3 = useRef(null);

  const contactRef = useRef(null);
  const membRef = useRef(null);


  function contactScrollTo(){
    
    if (contactRef && contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: 'smooth'});
      setTimeout(function(){
        document.location.hash = "#contact";
      }, 500); 
    }
  }

  function memberServicesScrollTo(){
    
    if (membRef && membRef.current) {
      membRef.current.scrollIntoView({ behavior: 'smooth'});
      setTimeout(function(){
        document.location.hash = "#services";
      }, 500); 
    }
  }


  return (
    <Layout>
       <section id="hero-section"> 
        
            <div className="container"><Navigation onContactClick={contactScrollTo} /></div>
            <div className="container">
                    <div className="columns hero-cols">
                      <div className="column is-6 hero-img-group">
                              <div className="tile hero-title-tertiary">
                                <p>Let’s own our health</p>
                              </div>
                              <div className="tile hero-title-secondary">
                                <p>Proactive, Preventative <br/>& Personalized</p>
                              </div>
                              <div className="tile hero-title">
                                <p>Menopause Care</p>
                              </div>
                          <StaticImage alt="Dr. Antoaneta Mueller, M.D., FACOG, Premier Board Certified Ob-Gyn - South Bay CA" loading="eager" quality="100" className="hero-img" imgStyle={{ objectFit: 'cover' }}  src="../images/home/hero-image@2x.png"></StaticImage>
                      </div>
                      <div className="column is-6 hero-desc-group">
                          <div className="tile is-ancestor is-vertical">
                              <div className="tile hero-title-tertiary">
                                <p>Let’s own our health</p>
                              </div>
                              <div className="tile hero-title-secondary">
                                <p>Proactive, Preventative <br/>& Personalized</p>
                              </div>
                              <div className="tile hero-title">
                                <p>Menopause Care</p>
                              </div>
                              <div className="tile name">
                              Dr. Antoaneta Mueller, M.D.
                              </div>
                              <div className="tile cert-title">
                              FACOG
                              </div>
                              <div className="tile cert-desc">
                              Premier Board Certified Ob-Gyn, having served in the<br/> Long Beach community since 2007.
                              </div>
                              <div className="tile book-btn" onClick={memberServicesScrollTo}>
                                <div className="btn-container">
                                  <div className="btn-content">Explore Our Services</div>
                                </div>
                              </div>
                          </div>
                      </div>
                    </div>
              </div>
       </section>

       <section id="quote-intro">
            <div className="container">
                    <div className="columns is-centered quote-cols">
                      <div className="column is-8 is-10-tablet quote-group">
                          <div className="tile is-ancestor is-vertical">
                                <div className="tile">
                                    <p className="quote-prim">WHY I STARTED</p>
                                </div>
                                <div className="tile">
                                    <p className="quote-secnd">Beyond My Menopause</p>
                                </div>
                                <div className="tile quote-desc">
                                    <img alt="Quote Icon" className="quote-icn" src={quoteIcon} />
                                    <p className="quote-para-offset"> Patients often come to me in tears as their day to day stressors have left them imbalanced preventing them from effectively managing their daily life. They have difficulty sleeping, report low energy and libido, poor memory and concentration and emotional lability.</p>
                                </div>
                                <div className="tile quote-desc">
                                    <p> Medical school and residency never prepared me for this challenge even though it affects all women eventually. Unhappy with conventional therapy,<br/> I threw myself into a more integrative approach.</p>
                                </div>
                                <div className="tile">
                                        <a href="https://beyondwomenshealth.com/about/" className="about-btn" >
                                            <div className="btn-container">
                                                <div className="btn-content">Meet Dr. Mueller</div>
                                            </div>
                                        </a>
                                </div>
                          </div>
                      </div>
                    </div>
            </div>
       </section>


       <section id="exp-intro">
            <div className="container">
                    <div className="columns is-centered exp-cols">
                      <div className="column is-8 is-10-tablet exp-group">
                          <div className="tile is-ancestor is-vertical">
                                <div className="tile exp-prim">
                                    <p>Having experienced</p>
                                    <div className="exp-highlight-cont"><span className="exp-highlight">similar symptoms myself</span><span className="highlight-border"></span></div>
                                    <p> I dove into menopause</p>
                                    <p> therapy and how best to </p>
                                    <p>manage these changes.</p>
                                </div>
                          </div>
                      </div>
                    </div>
            </div>
       </section>


       <section id="goal-intro">
            <div className="container">
                    <div className="columns is-centered goal-cols">
                      <div className="column is-8 is-10-tablet goal-group">
                          <div className="tile is-ancestor is-vertical">
                                <div className="tile goal-prim">
                                    <p>
                                        Whether you are perimenopausal or in menopause,<br/>
                                        my goal is to provide personalized therapy that will allow you<br/>
                                        to <b>regain your life back</b> and help you to feel balanced.
                                    </p>
                                </div>
                          </div>
                      </div>
                    </div>
            </div>
       </section>

       <section id="together-section">   
            <div className="container">
                    <div className="columns together-cols">
                      <div className="column is-6 together-img-group">
                          <div className="together-img-border">
                          <div className="together-img-wraper">
                          <StaticImage alt="A doctor providing care for her patient" loading="eager" quality="100" className="together-img" imgStyle={{ objectFit: 'cover' }}  src="../images/home/together-image@2x.png"></StaticImage>
                          </div>
                          </div>
                      </div>
                      <div className="column is-6 together-desc-group">
                          <div className="tile is-ancestor is-vertical">
                              <div className="tile together-title">
                                <p>We Will Reach <br/> Your Goals <span className="together-highlight">together</span></p>
                              </div>
                              <div className="tile together-desc">
                              Using an integrative approach that not only focuses on hormone therapy but also lifestyle changes.
                              </div>
                              <div className="tile book-btn">
                                <div className="btn-container" onClick={contactScrollTo}>
                                  <div className="btn-content">BOOK APPOINTMENT</div>
                                </div>
                              </div>
                          </div>
                      </div>
                    </div>
              </div>
       </section>

       <section id="faq-section">
            <div className="container">   
                <div className="faq-title-grp">
                        <div className="faq-title-prim">Frequently Asked Questions</div>
                        <div className="faq-title-secondary">Dr. Mueller explains menopause and treatment options.</div>
                </div>
            </div>
            <div className="container faq-container">
                   
                    <div className="faq-queries-container">
                            <div className="faq-grp" id="faq-what-is-menopause" ref={refFaq1}>
                                    <div className="faq-question">What is menopause?</div>
                                    <div className="faq-answer">
                                      <div>
                                      <div className="faq-highlight-cont"><span className="faq-highlight"><b>Menopause</b></span><span className="highlight-border"></span></div>
                                        &nbsp;is defined as the end of monthly cycles signaling the end of fertility for women. The mean age is 51. Clinically, menopause is 12 months without a menstrual cycle.
                                      </div>
                                    </div>
                            </div>
                            <div className="faq-grp" id="faq-what-is-perimenopause" ref={refFaq2}>
                                    <div className="faq-question">What is perimenopause?</div>
                                    <div className="faq-answer">
                                      <p>
                                        Perimenopause is the time around menopause that begins on average&nbsp; 
                                        <div className="faq-highlight-cont"><span className="faq-highlight"><b> 4 years </b></span><span className="highlight-border"></span></div>
                                        &nbsp;before the final menstrual period.
                                      </p>
                                    </div>
                            </div>
                            <div className="faq-grp" id="faq-what-is-happening-to-my-body" ref={refFaq3}>
                                    <div className="faq-question">What is happening to my body?</div>
                                    <div className="faq-answer">
                                      <p>
                                        The complex balance of estrogen and progesterone in our body is shifting leading to a disruption in our normal physical and mental state leaving us to feel “unbalanced”.
                                      </p>
                                      <p className="faq-points-title">
                                        Such symptoms often include:
                                      </p>
                                      <ul className="faq-points">
                                        <li>Hot flashes and night sweats</li>
                                        <li>Vaginal dryness and painful intercourse</li>
                                        <li>Absent or irregular cycles</li>
                                        <li>Heavier cycles</li>
                                        <li>Skin changes</li>
                                        <li>Poor libido</li>
                                        <li>Depression</li>
                                        <li>Irritability and anxiety</li>
                                        <li>Fatigue</li>
                                        <li>Poor memory</li>
                                        <li>Increase in abdominal fat</li>
                                        <li>Insomnia</li>
                                        <li>Joint pain</li>
                                      </ul>  
                                    </div>
                            </div>
        
                    </div>
            </div>
        </section>

        <section id="membership-section">
              <div className="container memb-fold-one">
                  <div className="columns is-gapless">
                      <div className="column is-12">
                          <div className="desc-grp">
                              <div className="title">Experiencing any of these symptoms?</div>
                              <div className="desc">Our practice offers an integrative, forward-thinking patient-centered experience, ensuring you have the tools and guidance needed to preserve your health and thrive during menopause and beyond.</div>
                          </div>
                      </div>
                  </div>
              </div>

              <div className="container memb-fold-two" ref={membRef}>
                  <div className="columns is-gapless">
                      <div className="column is-4">
                          <div className="img-grp">
                              <img alt="Membership Tier One" className="mem-img" src={membershipIconTwo} />
                          </div>
                      </div>
                      <div className="column is-8">
                          <div className="desc-grp">
                              <div className="title-primary">Proactive, &nbsp; Personalized, &nbsp;  Preventative</div>
                              <div className="title-secondary">
                                    <div className="mem-highlight-cont">
                                      <span className="mem-highlight">Membership Service</span>
                                      <span className="highlight-border"></span>
                                    </div>
                              </div>
                              <div className="desc">Embrace the opportunity to feel your best at every stage—take control of your health today.</div>
                              <div className="points">
                                    <ul className="mem-points">
                                        <li>
                                            <div className="pt-grp">
                                              <div className="pt-title">&#9679;&nbsp;&nbsp; Proactive Care</div>
                                              <div className="pt-desc">Stay ahead with advanced diagnostics and early intervention.</div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="pt-grp">
                                              <div className="pt-title">&#9679;&nbsp;&nbsp; Personalized Plans</div>
                                              <div className="pt-desc">Tailored care that reflects your unique biology and goals.</div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="pt-grp">
                                              <div className="pt-title">&#9679;&nbsp;&nbsp; Preventative Focus</div>
                                              <div className="pt-desc">Avoid chronic diseases with expert guidance and cutting-edge testing.</div>
                                            </div>
                                        </li>
                                    </ul>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div className="container memb-fold-three">
                  <div className="columns is-gapless">
                      <div className="column is-8">
                          <div className="desc-grp">
                              <div className="title-primary">Routine</div>
                              <div className="title-secondary">
                                    <div className="mem-highlight-cont">
                                      <span className="mem-highlight">Menopausal Service</span>
                                      <span className="highlight-border"></span>
                                    </div>
                              </div>
                              <div className="desc">
                                  <p>Experience my specialized practice, dedicated to supporting peri and menopausal women.</p>
                                  <p>Your health goals are my provider goals and together we will reach new levels of wellbeing tailored to your unique needs.</p>
                              </div>
                          </div>
                      </div>
                      <div className="column is-4">
                          <div className="img-grp">
                              <img alt="Membership Tier One" className="mem-img" src={membershipIconThree} />
                          </div>
                      </div>
                  </div>
              </div>
        </section>



        <section id="potenza">
            <div className="container">
                <div className="columns is-multiline">
                    <div className="column is-12 potenza-main-title">
                                    <div className="mem-highlight-cont">
                                      <span className="mem-highlight">Aesthetics Service</span>
                                      <span className="highlight-border"></span>
                                    </div>
                    </div>

                    <div className="column pot-img is-centered">
                        <StaticImage alt="Microneedling is a versatile treatment" className="potenza-img" quality="100" src="../images/home/beyond-aesthetics-image@2x.png" />
                    </div>   
                    <div className="column pot-desc">
                        <div className="column is-6 potenza-title">POTENZA RF MICRONEEDLING</div>
                        <div className="column is-6 potenza-title-secondary">LAUNCHING 2025</div>

                        <div className="column is-6 potenza-title-tertiary">Revitalize Your Skin at Every Stage of Life</div>
                        <div className="desc">
                            <p>Your skin deserves care that evolves with you. Potenza RF Microneedling is a versatile treatment designed to stimulate collagen and refresh your skin.</p>
                        </div>
                        
                        <div className="points">
                                    <ul className="potenza-points">
                                        <li>Customizable for all skin types.</li>
                                        <li>Treats areas on the face and body.</li>
                                        <li>Minimal downtime.</li>
                                        <li>Visible results after 1 treatment session.</li>
                                    </ul>
                        </div>
                        <div className="column is-6 potenza-title-tertiary">Comprehensive Care for All Ages</div>
                        <div className="desc">
                            <p>Stimulating collagen production to target:</p>
                        </div>
                        <div className="points multi">
                                    <ul className="potenza-points">
                                        <li>acne</li>
                                        <li>pores</li>
                                        <li>skin tightening</li>
                                        <li>wrinkles and fine lines</li>
                                    </ul>
                                    <ul className="potenza-points">
                                        <li>skin laxity</li>
                                        <li>stretch marks</li>
                                        <li>post-op scarring</li>
                                    </ul>
                        </div>
                    </div>
                     
                </div>
            </div>

       </section>

       <section id="ready-to-schedule">
            <div className="container">
                <img alt="Beyond Symbol" className="schedule-symbol" src={beyondSymbolWhite} />
                <div className="tile is-ancestor columns is-centered">
                    <div className="tile schedule-desc column is-8-tablet is-6-desktop ">
                        <p>
                        Please contact our office and 
                        <br />
                        <div className="schedule-highlight-cont"><span className="schedule-highlight">Book Your Consultation Today!</span><span className="highlight-border"></span></div>
                         </p>
                    </div>
                </div>
            </div>
       </section>

       <section id="contact" ref={contactRef}>
          <div className="container">
              <div className="columns">

                
                  <div className="column is-6 contact-info-grp">
                      <div className="tile is-ancestor is-vertical">
                          <div className="tile contact-title">Our Offices</div>
                          <div className="tile contact-title-name">BEYOND WOMEN’S HEALTH</div>
                          <div className="tile contact-desc"><img alt="Location" className="contact-image" quality="100" src={locationIcon} />3655 Lomita Blvd Suite 321<br/> Torrance CA, 90505</div>
                          <a href="tel:310-325-1198"className="tile contact-number"><img alt="Call" className="contact-image" quality="100" src={phoneIcon} />(310) 325-1198</a>
                      </div>
                  </div>
                  <div className="column is-6 contact-map">
                      <StaticImage alt="Location Map for Beyond My Menopause" className="contact-map-image" quality="100" src="../images/common/location-map.png" />
                  </div>
              </div>
          </div>
       </section>
    </Layout>
  )
}

export default IndexPage
